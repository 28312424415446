import React, { useCallback } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { Box, Button, Flex, Grid, Icon } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

export const CustomCarousel = ({ children }: { children: React.ReactNode }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    align: 'start',
  });

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <Box className="embla">
      <Box className="embla__viewport" ref={emblaRef}>
        <Grid className="embla__container" gridAutoFlow={'column'}>
          {children}
        </Grid>
      </Box>
      <Flex justifyContent={'center'} gap={3} mt={'24px'}>
        <Button
          className="embla__prev"
          onClick={scrollPrev}
          width={8}
          bgColor="white"
          rounded={'full'}>
          <Icon fontSize="24px" color="Black">
            <ChevronLeftIcon />
          </Icon>
        </Button>
        <Button
          className="embla__next"
          onClick={scrollNext}
          width={8}
          bgColor="white"
          rounded={'full'}>
          <Icon fontSize="24px" color="Black">
            <ChevronRightIcon />
          </Icon>
        </Button>
      </Flex>
    </Box>
  );
};
