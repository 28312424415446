import { Box, Stack } from '@chakra-ui/layout';
import { ReactNode } from 'react';

type GradientBackgroundProps = {
  children: ReactNode;
  firstColor?: string;
  secondColor?: string;
};

function GradientBackground({
  children,
  firstColor,
  secondColor,
}: GradientBackgroundProps) {
  return (
    <Box bgColor="#1C1E22" position="relative" overflow="hidden">
      <Stack
        position="absolute"
        top="-226px"
        transform="translateX(-50%)"
        left="50%"
        direction="row"
        zIndex={0}>
        {/* Bayangan Oval Pertama */}
        <Box
          width="570px"
          height="400px"
          borderRadius="50%"
          opacity="0.8"
          background={firstColor || '#0FC5FF99'}
          filter="blur(150px)"
        />

        {/* Bayangan Oval Kedua */}
        <Box
          width="570px"
          height="400px"
          borderRadius="50%"
          opacity="0.8"
          filter="blur(150px)"
          background={secondColor || '#541C4D'}
        />
      </Stack>
      {children}
    </Box>
  );
}

export { GradientBackground };
